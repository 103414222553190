import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FormattedTranslation from '@htmniseko/htm-ui/Intl/FormattedTranslationStyled';

const DEFAULT_COLOR = '#333';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledSpinner = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  margin-bottom: 15px;

  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
`;

const StyledBounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;  
  -webkit-animation-delay: ${({ delay }) => delay};
  animation-delay: ${({ delay }) => delay};
`;

const StyledText = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
`;

const LoadingBouncer = () => {
  const color = useSelector(state => state?.tenant?.theme?.colors?.primary ?? DEFAULT_COLOR);
  return (
    <StyledContainer>
      <StyledSpinner>
        <StyledBounce backgroundColor={color} />
        <StyledBounce backgroundColor={color} delay="-1.0s" />
      </StyledSpinner>
      <StyledText color={color}>
        <FormattedTranslation id="search.loading" />
      </StyledText>
    </StyledContainer>
  );
};

export default LoadingBouncer;
